import styled from 'styled-components'

export const AuthContainer = styled.div`
  background-color: #e9f3f5;
  min-height: 100vh;
  display: flex;
  width: 100vw;
  align-items: center;
  justify-content: center;
`

export const AuthCard = styled.div`
  max-width: 600px;
  width: 100%;
  border-radius: 15px;
  padding: 25px 50px;
  background-color: ${props => props.theme.colors.white};
`

export const AuthTitle = styled.div`
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  color: ${props => props.theme.colors.dark};
`

export const AuthSubTitle = styled.div`
  text-align: center;
  font-style: normal;
  opacity: 0.6;
  color: ${props => props.theme.colors.dark};
`

export const LoginWithGoogle = styled.button`
  display: flex;
  padding: 13px 13px;
  border-radius: 15px;
  width: 100%;
  padding-right: 25px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.grey7};
  color: ${props => props.theme.colors.darkGrey};
  font-weight: 600;
  margin-top: 45px;

  span {
    margin-left: auto;
    margin-right: auto;
    font-size: 15px;
  }
`

export const AuthSpacer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.6;
  margin: 35px 0;

  span {
    font-size: 16px;
    text-transform: uppercase;
    margin: 0 10px;
    color: ${props => props.theme.colors.grey9};
    min-width: 180px;
  }

  &::before,
  &::after {
    content: '';
    height: 1px;
    width: 100%;
    background-color: ${props => props.theme.colors.grey9};
  }
`

export const AuthFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.6;
  margin: 12px 0 0;

  span {
    min-width: 180px;
  }

  a {
    text-decoration: underline;
  }
`
