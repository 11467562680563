import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useGoogleLogin } from '@react-oauth/google'
import { login } from '@action/auth'
import * as Yup from 'yup'
import {
  AuthCard,
  AuthContainer,
  AuthFooter,
  AuthSpacer,
  AuthSubTitle,
  AuthTitle,
  LoginWithGoogle,
} from '@/styles/layouts/auth'
import Image from 'next/image'
import Form, { FormGroup, FormInput, FormLabel } from '@util/form'
import { Button } from '@style/components/button'
import { getLoginStatusSelector } from '@/services/selectors/auth/login'
import { REQUEST } from '@constant/reducer-status'
import { applyMiddleware } from '@middleware/index'
import Link from 'next/link'
import FormCheck from '@component/utils/form/check'
import { getRoute } from '@hook/router'

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().required(),
})

const initialValues = {
  email: '',
  password: '',
}

function Signin() {
  const Dispatch = useDispatch()
  const [status, errors] = useSelector(getLoginStatusSelector)

  const handleFormSubmit = useCallback(form => {
    Dispatch(login(form))
  }, [])

  const handleLogin = useGoogleLogin({
    onSuccess: ({ code }) => {
      Dispatch(login({ code }))
    },
    flow: 'auth-code',
    scope: 'profile email https://www.googleapis.com/auth/webmasters',
  })

  return (
    <AuthContainer>
      <AuthCard>
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginBottom: 16 }}>
          <Image height={90} width={90} src="/assets/images/logo-mini.svg" />
        </div>
        <AuthTitle>Login To Your Account</AuthTitle>
        <AuthSubTitle>Welcome back !</AuthSubTitle>
        <LoginWithGoogle onClick={handleLogin}>
          <Image src="/assets/icons/google.svg" height={16} width={15} />
          <span>Login with Google</span>
        </LoginWithGoogle>
        <AuthSpacer>
          <span>Or Login with email</span>
        </AuthSpacer>
        <Form initialValues={initialValues} validation={validationSchema} onSubmit={handleFormSubmit}>
          {form => (
            <>
              <FormGroup>
                <FormLabel htmlFor="email">Email Address</FormLabel>
                <FormInput id="email" name="email" placeholder="Email" form={form} withError errors={errors} />
              </FormGroup>
              <FormGroup>
                <FormLabel htmlFor="email">Password</FormLabel>
                <FormInput
                  id="password"
                  type="password"
                  name="password"
                  placeholder="Password"
                  form={form}
                  withError
                  errors={errors}
                />
              </FormGroup>
              <div className="flex justify-between mt-4">
                {/* <FormCheck name="remember-me" label="Remember Me" /> */}
                <Link href={getRoute('auth.forgot', {}, { email: form.values.email })}>
                  <span className="hover:text-purple text-blue cursor-pointer ml-auto"> Forgot Password</span>
                </Link>
              </div>
              <Button
                type="submit"
                btnFontSize="md"
                btnFontWeight="bold"
                btnSize="block"
                btnColor="white"
                btnBlock
                disabled={status == REQUEST}
                btnBackground="primary"
                style={{ marginTop: '2rem' }}
              >
                {status == REQUEST ? 'Loading...' : 'Log In'}
              </Button>
            </>
          )}
        </Form>
        <AuthFooter>
          <span>
            New on Seotisfy ?&nbsp;
            <Link href="/auth/signup">Free trial</Link>
          </span>
        </AuthFooter>
      </AuthCard>
    </AuthContainer>
  )
}

export default applyMiddleware(Signin, async ({ useMiddleware }) => {
  await useMiddleware('guest')
})
