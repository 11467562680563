import { createSelector } from 'reselect'

/**
 * get login status
 */
export const getLoginStatusSelector = createSelector(
  state => state.auth.login,
  login => [login.status, login.message]
)

export const getRegisterStatusSelector = createSelector(
  state => state.auth.singup,
  login => [login.status, login.message]
)
